@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

body {
  margin: 0;
  font-family: "Manrope", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
